import React from "react"
import styled from "styled-components"
import Box from "shared/components/Box"

// TO DO: hover effect?

const ListItemContainer = styled(Box)`
  width: 100%;
  border-radius: 0.2rem;
  border-top: 1px dashed #eaeaea;
  font-size: 1.5rem;
  ${props => props.hidden && `display: none;`}

  input[type="checkbox"] {
    position: relative;
    top: 5px;
  }

  p > i {
    position: relative;
    top: 5px;
  }
`

export const List = styled.div`
  ${props =>
    !props.nested &&
    `
    > ${ListItemContainer}:first-of-type {
      border-top: none;
    }
  `}

  > ${ListItemContainer}:nth-of-type(odd) {
    background-color: #fafafa;
  }

  > ${ListItemContainer}:nth-of-type(even) {
    background-color: #ffffff;
  }
`

export const ListItem = ({ children, ...props }) => {
  return <ListItemContainer {...props}>{children}</ListItemContainer>
}
