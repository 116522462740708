import React, { useState } from "react"
import Button from "shared/components/Button"
import Box from "shared/components/Box"
import { md } from "mill/utils/breakpoints"
import styled, { keyframes, css } from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

const hideScroll = keyframes`
  from, to { overflow: hidden; }
`

const FilterContainer = styled.div`
  z-index: ${zIndex.base + 1};

  background: white;
  ${props =>
    props.active &&
    `
      height: auto;
    `}
`

const Inner = styled.div`
  ${props =>
    props.$toggleable &&
    `
  display: grid;
  grid-template-columns: 1fr 1fr 7rem;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;

  > div:first-of-type {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  > div:nth-of-type(2) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  > div:nth-of-type(3) {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  @media ${md} {
    display: grid;
    grid-template-columns: 2fr 1fr 7rem;
    grid-column-gap: 1.5rem;

    > div:first-of-type,
    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      grid-column-start: auto;
      grid-column-end: auto;
    }
  }

  `}

  button {
    margin-bottom: 1.5rem;
  }
`

const animation = props =>
  css`
    ${hideScroll} 0.1s linear;
  `

const FilterOptions = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  animation: ${animation};

  label {
    margin-bottom: 0.3rem;
    display: block;
  }

  ${({ open }) =>
    open &&
    `
    overflow: visible;
    max-height: 1000px;
  `}

  @media ${md} {
    display: grid;
    flex-wrap: wrap;
    margin-left: 0rem;
  }
`

const OptionsInner = styled.div`
  background: rgb(244, 244, 244);
  border-radius: 6px;
  padding: 0 3rem;
  margin-bottom: 1.5rem;
`

const FilterBar = ({
  initiallyOpen,
  toggleable,
  visibleComponent,
  children
}) => {
  const [open, setOpen] = useState(initiallyOpen)

  const handleToggle = () => {
    if (!toggleable) {
      return
    }
    setOpen(!open)
  }

  return (
    <FilterContainer active={open} data-qa="filter-bar">
      <Inner $toggleable={toggleable}>
        {visibleComponent}
        {toggleable && <Button label="Filters" onClick={handleToggle} />}
      </Inner>
      {children && (
        <FilterOptions open={open}>
          <OptionsInner>
            <Box paddingBottom="medium">{children}</Box>
          </OptionsInner>
        </FilterOptions>
      )}
    </FilterContainer>
  )
}

FilterBar.defaultProps = {
  toggleable: true
}

export default FilterBar
