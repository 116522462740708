import React from "react"
import styled from "styled-components"
import Box from "shared/components/Box"
import useSearch from "shared/hooks/useSearch"
import { bodyFont } from "mill/utils/settings"

const Container = styled.div`
  label {
    font-size: 1.7rem;
    font-weight: 600;
    display: block;
    color: #30454b;
  }

  input {
    flex: 1;
    line-height: 4rem;
    height: 4rem;
    border-radius: 0 3px 3px 0;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    border-left: none;
    padding: 0.6rem 1.3rem;
    font-family: ${bodyFont};
    font-size: 1.5rem;
    line-height: 2.2rem;
    transition: all 0.2s ease-out;
    ${props =>
      props.includeIcon &&
      `border-radius: 0 3px 3px 0;
        border-left: none;`};
    &:focus {
      outline: none;
      border-color: ${props => props.theme.colors.primary};
      transition: all 0.2s ease-in;
    }
  }

  i.fa-cross {
    position: absolute;
    right: 7px;
    top: 50%;
    bottom: 50%;
    margin-top: -8px;
  }
`

const IconContainer = styled.div`
  background-color: ${props => props.theme.colors.primary};
  width: 4rem;
  border-radius: 3px 0 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`

const Icon = styled.i`
  color: white;
`

const SearchIcon = () => {
  return (
    <IconContainer>
      <Icon className="fa fa-search" />
    </IconContainer>
  )
}

const SearchSelector = ({ autoFocus, placeholder }) => {
  const { searchTerm, handleSearch, handleClearSearch } = useSearch()

  return (
    <Container>
      <Box display="flex" position={"relative"}>
        <SearchIcon />
        <input
          data-qa="search-input"
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
          autoFocus={autoFocus}
        />
        {searchTerm && (
          <i className="fa fa-cross" onClick={handleClearSearch} />
        )}
      </Box>
    </Container>
  )
}

SearchSelector.defaultProps = {
  autoFocus: false
}

export default SearchSelector
