import React from "react"
import styled from "styled-components"
import Imgix from "mill/components/Imgix"
import { sm, md } from "mill/utils/breakpoints"

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 25rem;

    @media ${sm} {
      max-height: 30rem;
    }

    @media ${md} {
      max-height: 35rem;
    }
  }
`

const Wrapper = styled.div`
  margin-bottom: 5rem;
`

const EmptyState = props => {
  const { imagePath, children } = props
  return (
    <Container data-qa="empty-state">
      <Wrapper>
        <Imgix srcPath={imagePath} maxWidth={510} maxHeight={510} />
        {children}
      </Wrapper>
    </Container>
  )
}

export default EmptyState
