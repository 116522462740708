import React from "react"
import styled from "styled-components"

const Dot = styled.span`
  padding: 0 0.8rem;
`

const DotSeparator = () => {
  return <Dot>&middot;</Dot>
}

export default DotSeparator
